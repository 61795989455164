<!--
   Created by Terence on 2024/6/23 - 15:12
-->
<template>
  <div>
    <div class="winnerp" v-if="playerList?.length">
      <div class="winnerp-title">已有{{ totalCount }}人参与</div>
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="loadMoreFc"
      >
        <div class="winnerp-content-card-winners">
          <div
            class="winner-item"
            v-for="(item, index) in playerList"
            :key="index"
            @click="handleToProfile(item)"
          >
            <div class="winner-item-avatar">
              <img
                class="winner-item-avatar-content"
                :src="item.avatar + '!mfit_w480_h480_webp'"
                alt=""
              />
            </div>
            <div class="winner-item-name otext2">
              {{ item.nickname }}
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <DuEmpty
      extClass="emptypage"
      v-if="inited && !playerList?.length"
      text="暂无参与者"
    />
  </div>
</template>

<script setup>
import { ref, reactive, shallowRef, onMounted, computed } from "vue";
import { List as VanList } from "vant";
import { DuEmpty } from "dangoui";
import LotteryApi from "@/apis/lottery";
import { getQuery } from "@/utils/url";
import { useTitle } from "@/hooks/useTitle";
import ItemCard from "@/views/Subject/components/item-card.vue";
import { invokeAppByYYB } from "@/utils/mob";

const { setTitle } = useTitle();

const inited = ref(false);
const loading = ref(false);
const finished = ref(false);
const playerList = ref([]);
const totalCount = ref(0);
const cursor = ref("");

const handleToProfile = (item) => {
  if (window.isInFlutter) {
    invokeAppByYYB({
      targetPath: `user/${item?.id}`,
      params: {},
    });
  }
};

const loadMoreFc = () => {
  initList();
};

const initList = async () => {
  const { id } = getQuery();

  loading.value = true;

  const tempParams = {
    lotteryId: id,
    limit: 100,
  };

  if (cursor.value) {
    tempParams["cursor"] = cursor.value;
  }

  const res = await LotteryApi.getAllPlayer(tempParams);
  inited.value = true;
  loading.value = false;

  const tempData = res.data.items;
  cursor.value = res.data.nextCursor;

  playerList.value = playerList.value.concat(tempData);
  // if (playerList.value.length) {
  //   playerList.value = playerList.value.concat(tempData)
  //   console.log('playerList.value', playerList.value);
  // } else {
  //   playerList.value = tempData;
  // }

  const hasMore = res?.data?.more;
  if (hasMore) {
    finished.value = false;
  } else {
    finished.value = true;
  }
};

onMounted(() => {
  initList();
  const { total } = getQuery();
  totalCount.value = total;

  setTitle("全部参与者");
});
</script>

<style lang="scss" scoped>
.winnerp {
  //padding-bottom: calc(68rpx + 74rpx);
  padding-bottom: 68rpx;
  &-title {
    padding: 32rpx 0 0 30rpx;
    color: #000;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 44rpx; /* 157.143% */
  }
  &-content {
    &-card {
      &-winners {
        padding: 24rpx;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20rpx; /* 可根据需要调整间距 */
        .winner-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &-name {
            text-align: center;
            color: #202426;
            text-align: center;
            font-size: 24rpx;
            font-style: normal;
            font-weight: 400;
            height: 72rpx;
            line-height: 36rpx; /* 150% */
          }
          &-avatar {
            display: block;
            width: 80rpx;
            height: 80rpx;
            border-radius: 100%;
            overflow: hidden;
            border: 2rpx solid #a06ee1;
            &-content {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              overflow: hidden;
              border: 4rpx solid #fff;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.emptypage {
  padding: 0 50rpx;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
